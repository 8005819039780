import React from 'react';

interface ModalBodyProps {
  children: React.ReactNode;
}

const ModalBody: React.FC<ModalBodyProps> = ({ children }) => {
  return (
    <div className="p-4 text-neutral-300 overflow-y-auto">
      {children}
    </div>
  );
};

export default ModalBody;
