import React from 'react';

interface ModalFooterProps {
  children: React.ReactNode;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  return (
    <div className="flex justify-center items-center gap-x-2 py-3 px-4 border-t border-neutral-600">
      {children}
    </div>
  );
};

export default ModalFooter;
