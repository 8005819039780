import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <div
      className={`fixed inset-0 z-50 ${isOpen ? 'flex' : 'hidden'} items-center justify-center rounded-md`}
      role="dialog"
      aria-modal="true"
    >
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
        onClick={onClose}
      ></div>

      {/* Modal content */}
      <div className="relative bg-neutral-700 rounded-md shadow-lg z-50 w-full max-w-lg mx-auto mx-4 desktop:mx-0">
        {children}
      </div>
    </div>
  );
};

export default Modal;